import React, {useState, useEffect} from "react";
import { Grid, InputLabel, MenuItem, Select, TextField, Tooltip, FormControlLabel, Checkbox, CircularProgress, Button } from "@mui/material";
import { AttendanceApi } from "@unity/components";

export default function AttendExtension(props) {
    const [patterns, setPatterns] = useState(false);
    const [attendData, setAttendData] = useState(false);
    const [error, setError] = useState(false);
    
    const getPatterns = async () => {
        let patternRes = await AttendanceApi.getPattern();
        if(!patternRes.success || patternRes.status === 500)
        {
            setError("Failed to Pull Shift Patterns, Please Refresh");
            return;
        }
        setPatterns(patternRes.data);

        let res = await AttendanceApi.getUserSetup(props.data.uuid);
        if(!res.success || res.statusCode === 500)
        {
            setError("Failed to pull attendance settings, please refresh");
            return;
        }

        if(res.data.id === null)
        {
            setError("First time user attendance setup. Please Wait...");
            const profileSetup = await AttendanceApi.postUserSetup({
                user_uuid: props.data.uuid,
                user_name: props.data.name,
                user_email: props.data.email,
                agent_id: props.data.id,
                contact_id: props.data.contact_id,
                holiday_total: 0,
                holiday_balance: 0,
                flexi_balance: "00:00:00",
                shift_pattern_id: patternRes.data[0].id
            });

            if( profileSetup.statusCode === 500 )
            {
                setError("Failed to create attendance profile. Please refresh and try again");
                return;
            }

            res = await AttendanceApi.getUserSetup(props.data.uuid);
            if(!res.success || res.statusCode === 500)
            {
                setError("Failed to pull attendance settings, please refresh");
                return;
            }

            setError(false);

        }
        setAttendData(res.data);

        props.handleExtensionChange("agent_id", res.data.agent_id);
        props.handleExtensionChange("contact_id", res.data.contact_id);
        props.handleExtensionChange("notification_preference", res.data.notification_preference);
    }

    useEffect(() => {
        getPatterns();
    }, []);

    return patterns && attendData && !error ? (
        <div className="block">
            <div className="container-fluid">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Attendance Settings</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Shift Pattern</InputLabel>
                        <Select
                        fullWidth
                        defaultValue={attendData.shift_pattern_id}
                        name="shift_pattern_id"
                        disabled={(props.type === "view") || !props.context.auth.access.personnel.MA}
                        onChange={(e) => {
                            props.handleExtensionChange(e.target.name, e.target.value)
                        }}
                        >
                            {
                                patterns.map((item, key) => {
                                    return (
                                        <MenuItem key={key} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Current Holiday Balance</InputLabel>
                        <TextField
                            fullWidth
                            defaultValue={attendData.holiday_balance}
                            disabled={(props.type === "view") || !props.context.auth.access.personnel.MA}
                            onChange={(e) => { props.handleExtensionChange(e.target.name, e.target.value) }}
                            type={"number"}
                            name="holiday_balance"
                            helpertext={"The user's current holiday total remaining"}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Holiday Total</InputLabel>
                        <TextField
                            fullWidth
                            defaultValue={attendData.holiday_total}
                            disabled={(props.type === "view") || !props.context.auth.access.personnel.MA}
                            onChange={(e) => { props.handleExtensionChange(e.target.name, e.target.value) }}
                            type={"number"}
                            name="holiday_total"
                            helpertext={"The users's total number of holidays"}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <Tooltip title="If checked - this user will recieve an email reminder in the morning if they forget to clock in. Uncheck this if you do not wish this user to be reminded to clock in on their work days">
                        <FormControlLabel
                            control={
                            <Checkbox
                                onChange={(e) => { props.handleExtensionChange(e.target.name, !props.data.notification_preference) }}
                                name="notification_preference"
                                checked={Boolean(props.data.notification_preference)}
                                color="primary"
                                disabled={(props.type === "view") || !props.context.auth.access.personnel.MA}
                            />
                            }
                            label="Clock In Reminder Notification"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>End Date</InputLabel>
                        <TextField
                            fullWidth
                            defaultValue={attendData.end_date}
                            onChange={(e) => { props.handleExtensionChange(e.target.name, e.target.value) }}
                            type={"date"}
                            disabled={(props.type === "view") || !props.context.auth.access.personnel.MA}
                            name="end_date"
                            id="end_date"
                        />
                    </Grid>
                    {
                        attendData.end_date === null ? (<></>) : (
                            <Grid item xs={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => { 
                                        props.handleExtensionChange("end_date", null);
                                        document.getElementById("end_date").value = null;
                                    }}
                                >
                                    Clear End Date    
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </div>
        </div>
    ) : (
        <div style={{ padding: "20px" }}>
            <CircularProgress size={36} color={"secondary"} variant={error ? "determinate" : "indeterminate"} value={error ? 100 : 0}/> <br/>
            {
                error ? (
                    <p>{error}</p>
                ) : (
                    <p>Loading Attendance Extension...</p>
                )
            }
            
        </div>
    )
}