import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppButtonComponent as AppButton } from "@unity/components";
import Loading from "../common/Loading";
import { RenderPageTemplate } from "../mapping";
import { PersonnelApi } from "@unity/components";
import { CanRead, CanCreate, CanUpdate } from "../services/Permissions";

export default function Personnel({ route, context, history }) {
  const [data, setData] = useState(false);

  const read = CanRead("personnel");
  const create = CanCreate("personnel");
  const edit = CanUpdate("personnel");


  const columns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "extension", headerName: "Phone", width: 100 },
    { field: "mobile", headerName: "Mobile", width: 100 },
    { field: "position", headerName: "Position", width: 300 },
    { field: "role_name", headerName: "Role", width: 100 },
    {
      field: "1",
      width: 300,
      headerName: "Action",
        renderCell: (out) => {
          return (
            <>
              {read && (
                <Link to={{pathname: `/personnel/view/${out.row.id}`}}>
                  <AppButton
                    text="View"
                    btnIcon="visibility"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                    }}
                  />
                </Link>
              )}

              {edit && (
                <Link to={{pathname: `/personnel/edit/${out.row.id}`}}>
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
            </>
          );
        },
    },
  ];

  const getPersonnelIndex = async () => {
    const data = await PersonnelApi.getPersonnel();
    setData(data.data);
  };

  useEffect(() => {
    getPersonnelIndex();
  }, []);

  var select;

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
  };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "index");


  if (data) {
    return (
      <>
        <RenderPage
          columns={columns}
          data={data}
          options={options}
          context={context}
          history={history}
          route={route}
          create={create}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
